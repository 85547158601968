require('prismjs/plugins/command-line/prism-command-line.css');
require('prismjs/plugins/line-numbers/prism-line-numbers.css');

/* exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.scrollTo(0, 0);
  return false;
};
 */